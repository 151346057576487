import { Epoch } from "./epoch";
import { DayOfWeek1, DaysSinceEpoch, MsSinceEpoch } from "./units";

export namespace Weekday {
  export const WEEKDAY_SHORT = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ];
  export const WEEKDAY_FULL = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //
  // 21.4.1.6 Week Day
  //
  export function fromDse(dse: DaysSinceEpoch): DayOfWeek1 {
    let result = (dse + 4) % 7;
    if (result < 0) {
      result += 7;
    }
    return result as DayOfWeek1;
  }

  export function fromMse(mse: MsSinceEpoch): DayOfWeek1 {
    return Weekday.fromDse(Epoch.mseToDse(mse));
  }

  export function shortName(dow: DayOfWeek1): string {
    return WEEKDAY_SHORT[dow - 1];
  }

  export function fullName(dow: DayOfWeek1): string {
    return WEEKDAY_FULL[dow - 1];
  }
}
