import { TimeUnit } from "./mod";
import { NaiveDate } from "./naive-date";
import { NaiveTime } from "./naive-time";
import { DaysSinceEpoch, MsSinceEpoch } from "./units/units";

export class NaiveDateTime {
  readonly ymd: NaiveDate;
  readonly timeOfDay: NaiveTime;

  constructor(ymd: NaiveDate, timeOfDay: NaiveTime) {
    this.ymd = ymd;
    this.timeOfDay = timeOfDay;
  }

  static fromMse(mse: MsSinceEpoch): NaiveDateTime {
    const { div, rem } = NaiveTime.divByDay(mse);
    return new NaiveDateTime(
      NaiveDate.fromDse(div as DaysSinceEpoch),
      new NaiveTime(rem)
    );
  }

  add(delta: TimeUnit): NaiveDateTime {
    const added = this.timeOfDay.add(delta);
    if (added.days == 1) {
      return new NaiveDateTime(this.ymd, added);
    }
    const rem = added.sub({ days: added.days });
    return new NaiveDateTime(this.ymd.addDays(added.days), rem);
  }
}
