import { NaiveDate, NaiveTime, Result } from "./mod";
import { NaiveDateTime } from "./naive-datetime";
import { AnyTimezone, LogicalTimezone, Utc, Local } from "./timezone";
import { DayOfWeek1, MsSinceEpoch } from "./units/units";

const RFC3339_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;

export class DateTime<TzType extends string> {
  static utc = Utc;
  static local = Local;

  readonly dt: NaiveDateTime;
  readonly tz: LogicalTimezone<TzType>;

  constructor(dt: NaiveDateTime, tz: LogicalTimezone<TzType>) {
    this.dt = dt;
    this.tz = tz;
  }

  static fromMse<TzType extends string>(
    mse: MsSinceEpoch,
    tz: LogicalTimezone<TzType>
  ): DateTime<TzType> {
    console.log(tz.info.offset);
    if (tz.info.offset.asMs == 0) {
      return new DateTime(NaiveDateTime.fromMse(mse), tz);
    }
    return new DateTime(
      NaiveDateTime.fromMse((mse + tz.info.offset.signedAsMs) as MsSinceEpoch),
      tz
    );
  }

  rfc3339(): string {
    const sb: string[] = ["", "T", "", ""];
    sb[0] = this.dt.ymd.rfc3339();
    sb[2] = this.dt.timeOfDay.rfc3339();
    sb[3] = this.tz.rfc3339;
    return sb.join("");
  }

  static fromRfc3339(rfc3339: string): Result<DateTime<AnyTimezone>> {
    const match = rfc3339.match(RFC3339_REGEX);
    if (!match) return Error("regex-fail");

    const [_, year, month, day, hour, minute, second, __, timezone] = match;

    const nd = NaiveDate.fromYmd10Str(year, month, day);
    if (Result.isErr(nd)) return nd as Error;

    const nt = NaiveTime.fromHmsStr(hour, minute, second);
    if (Result.isErr(nt)) return nt as Error;

    // todo: check timezone
    const dt = new DateTime(
      new NaiveDateTime(Result.unwrap(nd), Result.unwrap(nt)),
      Utc
    );
    return dt;
  }

  get dow(): DayOfWeek1 {
    return this.dt.ymd.dayOfWeek;
  }
}

export const rfc3339 = DateTime.fromRfc3339;
