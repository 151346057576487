import { DayOfMonth1, Month1 } from "./units";
import { Year } from "./year";

export type Ym1 = {
  yr: number;
  mth: Month1;
};

export namespace Month {
  export const MONTHS_OF_YEAR = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  export const MONTHS_OF_YEAR_SHORT = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  export const DAYS_IN_MONTH = [
    [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], // Non-leap year
    [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], // Leap year
  ];
  export const MONTH_START_OF_YEAR = [
    [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365], // Non-leap year
    [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366], // Leap year
  ];
}

export namespace YearMonth {
  export function daysInMonth({ yr, mth }: Ym1): number {
    return Month.DAYS_IN_MONTH[Year.isLeapYear(yr) ? 1 : 0][mth - 1];
  }

  export function doyForMonthStart({ yr, mth }: Ym1) {
    return Month.MONTH_START_OF_YEAR[Year.isLeapYear(yr) ? 1 : 0][mth - 1];
  }

  export function isDayValid(ym1: Ym1, day: DayOfMonth1): boolean {
    // console.log("daysInMonth", daysInMonth(ym1));
    return day > 0 && day <= daysInMonth(ym1);
  }
}

export class MonthInfo {
  constructor(year: number, month: number) {}
}
