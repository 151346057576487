import { MathUtils } from "@lona/math";
import { Hms, HmsLike } from "./hour-mins-secs";
import { Result } from "../mod";
import { Time } from "../time";
import { Day, DaysSinceEpoch, Ms } from "./units";

export interface TimeUnitLike extends HmsLike {
  readonly days: number;
  readonly ms: number;
}
export type TimeUnitLikeOpt = Optional<TimeUnitLike>;

export class TimeUnit implements TimeUnitLike {
  readonly sign: 1 | -1;

  constructor(asMs: number) {
    this.sign = asMs > 0 ? 1 : -1;
    this.asMs = asMs * this.sign;
  }

  // static toTimeOfDay(ms: number): TimeUnit {
  //   console.log(ms);
  //   console.log(ms % Time.MS_PER_DAY);
  //   return new TimeUnit(MathUtils.mod(ms, Time.MS_PER_DAY));
  // }

  /**
   * @param ms
   *    Milliseconds from [-Inf..Inf]
   *    -5s represents 5s before midnight, ie. 23:59:55
   */
  static divByDay(ms: number): { div: Day; rem: Ms } {
    const rem = MathUtils.mod(ms, Time.MS_PER_DAY) as Ms;
    return {
      div: Math.floor(ms / Time.MS_PER_DAY) as Day,
      rem,
    };
  }

  static fromHms(hms: Optional<HmsLike>): TimeUnit {
    return new TimeUnit(Hms.toMs(hms));
  }

  static fromHmsStr(
    hours: Option<string> = null,
    minutes: Option<string> = null,
    seconds: Option<string> = null
  ): Result<TimeUnit> {
    const hrs = hours ? parseInt(hours) : 0;
    if (isNaN(hrs)) return Error(`parse/hrs: ${hours}`);

    const mins = minutes ? parseInt(minutes) : 0;
    if (isNaN(mins)) return Error(`parse/mins: ${minutes}`);

    const secs = seconds ? parseInt(seconds) : 0;
    if (isNaN(secs)) return Error(`parse/secs: ${seconds}`);

    const hms = {
      hrs,
      mins,
      secs,
    };
    if (!Hms.isStrict(hms)) return Error(`parse/strict: ${hms}`);

    return TimeUnit.fromHms(hms);
  }

  /**
   * Time relative to their larger time unit.
   *
   * ie.
   *   Let {NoUnit} represent the largest time unit (defined as decade, century, etc)
   *   possible.
   *
   *
   *   days -> daysOf{NoUnit}
   *   hrs -> hrOfDay
   *   mins -> minOfDay
   *   secs -> secOfMin
   *   ms -> msOfSec
   */
  get days(): number {
    return Math.floor(this.daysF);
  }
  get hrs(): number {
    return Math.floor(this.hrsF);
  }
  get mins(): number {
    return Math.floor(this.minsF);
  }
  get secs(): number {
    return Math.floor(this.secsF);
  }
  get ms(): number {
    return this.asMs % Time.MS_PER_DAY_INV;
  }

  get daysF(): number {
    return this.days;
  }
  get hrsF(): number {
    return (this.asMs % Time.MS_PER_DAY) * Time.MS_PER_HR_INV;
  }
  get minsF(): number {
    return (this.asMs % Time.MS_PER_HR) * Time.MS_PER_MIN_INV;
  }
  get secsF(): number {
    return (this.asMs % Time.MS_PER_HR) * Time.MS_PER_MIN_INV;
  }

  get signedAsMs(): number {
    return this.sign * this.asMs;
  }

  /**
   * UNSIGNED
   *
   * as{Ms|Days|Mins|Seconds}
   *
   * An absolute unit of time relative to nothing else.
   *
   * ie.
   *   Let {NoUnit} represent the largest time unit (defined as decade, century, etc)
   *   possible.
   *
   *   days -> daysOf{NoUnit}
   *   hrs -> hrOf{NoUnit}
   *   mins -> minOf{NoUnit}
   *   secs -> secOf{NoUnit}
   *   ms -> msOf{NoUnit}
   */
  readonly asMs: number;

  get asDays(): number {
    return Math.floor(this.asDaysF);
  }
  get asHrs(): number {
    return Math.floor(this.asHrsF);
  }
  get asMins(): number {
    return Math.floor(this.asMinsF);
  }
  get asSecs(): number {
    return Math.floor(this.asSecsF);
  }

  get asDaysF(): number {
    return this.asMs * Time.MS_PER_DAY_INV;
  }
  get asHrsF(): number {
    return this.asMs * Time.MS_PER_HR_INV;
  }
  get asMinsF(): number {
    return this.asMs * Time.MS_PER_MIN_INV;
  }
  get asSecsF(): number {
    return this.asMs * Time.MS_PER_SEC_INV;
  }

  /*
   * Methods
   */
  add(delta: TimeUnitLikeOpt): TimeUnit {
    return new TimeUnit(this.asMs + TimeUnit.from(delta).asMs);
  }

  sub(delta: TimeUnitLikeOpt): TimeUnit {
    return new TimeUnit(this.asMs - TimeUnit.from(delta).asMs);
  }

  rfc3339(): string {
    // todo: assert sign is positive
    return [
      this.hrs.toString().padStart(2, "0"),
      this.mins.toString().padStart(2, "0"),
      this.secs.toString().padStart(2, "0"),
    ].join(":");
  }

  asSignedHm() {
    return [
      this.sign > 0 ? "+" : "-",
      this.hrs.toString().padStart(2, "0"),
      ":",
      this.mins.toString().padStart(2, "0"),
    ].join("");
  }
}

export namespace TimeUnit {
  export const ZERO = new TimeUnit(0);

  export function asMs(delta: TimeUnitLikeOpt): number {
    if (delta instanceof TimeUnit) return delta.asMs;
    return (
      (delta.days ? delta.days * Time.MS_PER_DAY : 0) +
      (delta.hrs ? delta.hrs * Time.MS_PER_HR : 0) +
      (delta.mins ? delta.mins * Time.MS_PER_MIN : 0) +
      (delta.secs ? delta.secs * Time.MS_PER_SEC : 0) +
      (delta.ms ?? 0)
    );
  }

  export function fromMs(ms: Ms): TimeUnit {
    return new TimeUnit(ms);
  }

  export function from(delta: TimeUnitLikeOpt): TimeUnit {
    if (delta instanceof TimeUnit) return delta;
    return new TimeUnit(TimeUnit.asMs(delta));
  }

  export function msToString(
    ms: number,
    formatter: TimeDeltaFormatter = DEFAULT_FORMATTER
  ): string {
    return formatter.format(
      TimeUnit.from({
        ms,
      })
    );
  }
}

export interface TimeDeltaFormatter {
  format(delta: TimeUnit): string;
}

export class EnTimeDeltaFormatter implements TimeDeltaFormatter {
  static pluralize(s: string, n: number) {
    if (n > 1) return s + "s";
    return s;
  }

  format(delta: TimeUnit): string {
    const pluralize = EnTimeDeltaFormatter.pluralize;
    const sb: string[] = [];
    if (delta.days > 0) {
      sb.push(`${delta.days} ${pluralize("day", delta.days)}`);
    }
    if (delta.hrs > 0) {
      sb.push(`${delta.hrs} ${pluralize("hour", delta.hrs)}`);
    }
    if (delta.mins > 0) {
      sb.push(`${delta.hrs} ${pluralize("min", delta.mins)}`);
    }
    if (delta.secs > 0) {
      sb.push(`${delta.secs} ${pluralize("sec", delta.secs)}`);
    }
    return sb.join(", ");
  }
}

export const DEFAULT_FORMATTER = new EnTimeDeltaFormatter();
