import { DateTime } from "./mod";
import { Epoch } from "./units/epoch";
import { TimeUnit } from "./units/time-unit";
import { MsSinceEpoch } from "./units/units";

export interface TimezoneInfo {
  tzabbr?: Option<string>; // "UTC", "PST", "PDT"
  tzname?: Option<string>; // "America/New_York", "UTC"
  offset: TimeUnit;
}

export namespace TimezoneInfo {
  export function localTzName(): string {
    return Intl.DateTimeFormat("en-US").resolvedOptions().timeZone;
  }

  export function localTzabbr() {
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZoneName: "short",
    });
    const parts = formatter.formatToParts(new Date());
    const timeZone = parts.find((part) => part.type === "timeZoneName");
    return timeZone ? timeZone.value : null;
  }

  export function local(): TimezoneInfo {
    return {
      tzabbr: localTzabbr(),
      tzname: localTzName(),
      offset: TimeUnit.fromHms({
        // NOTE: Date().getTzOffset is reversed from the RFC3339 implementation
        // ie.
        //   For tzname = "America/New_York"
        //     js::Date -> getTimezoneOffset() == "+5:00"
        //     rfc3339  ->                     == "-5:00"
        //
        mins: -new Date().getTimezoneOffset(),
      }),
    };
  }
}

export interface LogicalTimezone<Id extends string> {
  id: Id;
  info: TimezoneInfo;
  rfc3339: string;
}

class UtcTimezone implements LogicalTimezone<"utc"> {
  id: "utc" = "utc";
  info = {
    tzname: "UTC",
    offset: TimeUnit.ZERO,
  };
  rfc3339: string = "Z";

  now(): DateTime<Utc> {
    return DateTime.fromMse(Epoch.currentMse(), Utc);
  }
}

export const Utc = new UtcTimezone();
export type Utc = "utc";

export class FixedTimezone<Id extends string> implements LogicalTimezone<Id> {
  id: Id;
  info: TimezoneInfo;

  constructor(id: Id, info: TimezoneInfo) {
    this.id = id;
    this.info = info;
  }

  get rfc3339(): string {
    return this.info.offset.asSignedHm();
  }
}

export type AnyTimezone = Utc | string;

export class LocalTimezone implements LogicalTimezone<Local> {
  id: Local = "local";
  info: TimezoneInfo = TimezoneInfo.local();

  now(): DateTime<Local> {
    return DateTime.fromMse(Epoch.currentMse(), Local);
  }

  get rfc3339(): string {
    return this.info.offset.asSignedHm();
  }

  fromMse(mse: MsSinceEpoch): DateTime<Local> {
    return DateTime.fromMse(mse, Local);
  }
}

export const Local = new LocalTimezone();
export type Local = "local";
