import { Result } from "./mod";
import { Time } from "./time";
import { Month, YearMonth } from "./units/month";
import {
  DayOfMonth,
  DayOfMonth0,
  DayOfMonth1,
  DayOfWeek1,
  DayOfYear1,
  DaysSinceEpoch,
  Month0,
  Month1,
  MonthOfYear,
  MsSinceEpoch,
} from "./units/units";
import { Weekday } from "./units/weekday";
import { Year } from "./units/year";

export type CachedYmdInfo = {
  dayOfWeek: DayOfWeek1;
};

export interface YmdLike<Index> {
  readonly yr: number;
  readonly mth: MonthOfYear<Index>;
  readonly day: DayOfMonth<Index>;
}

export type Ymd1Like = YmdLike<1>;
export type Ymd0Like = YmdLike<0>;

export class YearMonthDay implements Ymd1Like {
  readonly ymd1: Ymd1Like;
  readonly cached: Optional<CachedYmdInfo>;

  constructor(ymd1: Ymd1Like, info: Option<CachedYmdInfo> = null) {
    this.ymd1 = ymd1;
    this.cached = info ?? {};
  }

  /*
   * [Constructors]
   */
  static fromYmd1Unchecked(
    year: number,
    month1: number,
    day1: number
  ): YearMonthDay {
    return new YearMonthDay({
      yr: year,
      mth: month1 as Month1,
      day: day1 as DayOfMonth1,
    });
  }

  static fromYmd1(
    year: number,
    month1: number,
    day1: number
  ): Result<YearMonthDay> {
    if (month1 < 1 || month1 > 12) return Error("month/bounds");
    const ymd = {
      yr: year,
      mth: month1 as Month1,
      day: day1 as DayOfMonth1,
    };
    // console.log("bounds check", ymd, ymd.day);
    if (!YearMonth.isDayValid(ymd, ymd.day)) return Error("day/bounds");
    return new YearMonthDay(ymd);
  }

  static fromYmd10Str(
    year: string,
    month1: string,
    day0: string
  ): Result<YearMonthDay> {
    const yr = parseInt(year);
    if (isNaN(yr)) return Error(`parse/yr: ${year}`);

    const mth = parseInt(month1);
    if (isNaN(mth)) return Error(`parse/mth: ${month1}`);

    const day = parseInt(day0);
    if (isNaN(day)) return Error(`parse/day: ${day0}`);

    return YearMonthDay.fromYmd1(yr, mth, day + 1);
  }

  static fromYmd0(year: number, month0: number, day0: number): YearMonthDay {
    return new YearMonthDay({
      yr: year,
      mth: (month0 + 1) as Month1,
      day: (day0 + 1) as DayOfMonth1,
    });
  }

  static fromDse(dse: DaysSinceEpoch): YearMonthDay {
    return new YearMonthDay(Ymd.fromDse(dse));
  }

  static fromMse(mse: MsSinceEpoch): YearMonthDay {
    return new YearMonthDay(
      Ymd.fromDse(Math.floor(mse * Time.MS_PER_DAY_INV) as DaysSinceEpoch)
    );
  }

  /**
   * [Interface] Ymd1
   */
  get yr(): number {
    return this.ymd1.yr;
  }

  get mth(): Month1 {
    return this.ymd1.mth;
  }

  get day(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Month
   */
  get month0(): Month0 {
    return (this.ymd1.mth - 1) as Month0;
  }

  get month1(): Month1 {
    return this.ymd1.mth;
  }

  get dayOfMonth(): Month1 {
    return this.ymd1.mth;
  }

  /**
   * [Getters] Week-Day
   */
  get dayOfWeek(): DayOfWeek1 {
    if (this.cached.dayOfWeek) return this.cached.dayOfWeek;
    return (this.cached.dayOfWeek = YearMonthDay.dayOfWeek(this));
  }

  /**
   * [Getters] Month-Day
   */
  get day0(): DayOfMonth0 {
    return (this.ymd1.day - 1) as DayOfMonth0;
  }

  get day1(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Year-Day
   */
  get dayOfYear(): DayOfYear1 {
    return YearMonthDay.dayOfYear(this);
  }

  get daysSinceEpoch(): DaysSinceEpoch {
    return YearMonthDay.daysSinceEpoch(this.ymd1);
  }

  get dse(): DaysSinceEpoch {
    return YearMonthDay.daysSinceEpoch(this.ymd1);
  }

  /**
   * Ops
   */

  addDays(days: number): NaiveDate {
    return NaiveDate.fromDse((this.dse + days) as DaysSinceEpoch);
  }

  /**
   * toString
   */
  rfc3339(): string {
    return [
      this.yr,
      String(this.month1).padStart(2, "0"),
      String(this.day1).padStart(2, "0"),
    ].join("-");
  }
}

export type NaiveDate = YearMonthDay;
export const NaiveDate = YearMonthDay;

export namespace YearMonthDay {
  export function daysSinceEpoch(ymd: Ymd1Like): DaysSinceEpoch {
    return (Year.dseFromYear(ymd.yr) +
      YearMonth.doyForMonthStart(ymd) +
      ymd.day -
      1) as DaysSinceEpoch;
  }
  export const dse = daysSinceEpoch;

  export function dayOfWeek(ymd: Ymd1Like): DayOfWeek1 {
    const weekday = Weekday.fromDse(YearMonthDay.dse(ymd));
    return (weekday != 0 ? weekday : 7) as DayOfWeek1;
  }

  export function dayOfYear(ymd: Ymd1Like): DayOfYear1 {
    return (Month.MONTH_START_OF_YEAR[Year.isLeapYear(ymd.yr) ? 1 : 0][
      ymd.mth - 1
    ] + ymd.day) as DayOfYear1;
  }
}

export namespace Ymd {
  const CYCLE_IN_YEARS = 400;
  const CYCLE_IN_DAYS = 146097;
  const CYCLE_IN_DAYS_INV = 1 / CYCLE_IN_DAYS;

  const RATA_DIE_1970_JAN1 = 719468;

  const s = 3670;
  const K = RATA_DIE_1970_JAN1 + s * CYCLE_IN_DAYS;
  const L = s * CYCLE_IN_YEARS;

  const U16_MAX = 65536;
  const U16_MAX_INV = 1 / U16_MAX;

  const U32_MAX = 4294967296;
  const U32_MAX_INV = 1 / U32_MAX;

  const C_2939745 = 2939745;
  const C_2939745_INV = 1 / C_2939745;

  const C_4_INV = 1 / 4;

  const C_2141 = 2141;
  const C_2141_INV = 1 / C_2141;

  //
  // js::ToYearMonthDay
  // https://github.com/mozilla/gecko-dev/blob/master/js/src/jsdate.cpp
  //
  // Adapted from Mozilla's temporal code.
  //
  // Fuzzy tested from Epoch(0...3000-1-1) against a Rust reference implementation
  //
  export function fromDseGecko(dse: DaysSinceEpoch): Ymd1Like {
    const N_U = dse;
    const N = N_U + K;

    const N_1 = 4 * N + 3;
    const C = Math.floor(N_1 * CYCLE_IN_DAYS_INV);
    const N_C = Math.floor((N_1 % CYCLE_IN_DAYS) * C_4_INV);

    // Year of the century Z and day of the year N_Y:
    const N_2 = 4 * N_C + 3;
    const P_2 = C_2939745 * N_2;
    const Z = Math.floor(P_2 * U32_MAX_INV);
    const N_Y = Math.floor(
      Math.floor((P_2 % U32_MAX) * C_2939745_INV) * C_4_INV
    );

    const Y = 100 * C + Z;
    const N_3 = C_2141 * N_Y + 132377; // 132377 = 197913 - 65536
    const M = Math.floor(N_3 * U16_MAX_INV);
    const D = Math.floor((N_3 % U16_MAX) * C_2141_INV);

    const daysFromMar01ToJan01 = 306;
    const J = N_Y >= daysFromMar01ToJan01;
    const Y_G = Math.floor(Y - L + (J ? 1 : 0));
    const M_G = J ? M - 12 : M;
    const D_G = D;

    return {
      yr: Y_G,
      mth: (M_G + 1) as Month1,
      day: (D_G + 1) as DayOfMonth1,
    };
  }

  export const fromDse = fromDseGecko;
}
