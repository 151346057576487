import { DateTime } from "../datetime";
import { Utc } from "../timezone";

export class RRule {
  private config: RRule.ResolvedConfig;

  constructor({ freq, interval, byweekday, dtstart, until }: RRule.Config) {
    this.config = {
      freq: freq != null ? freq : "yearly",
      interval: interval != null ? interval : 1,
      dtstart: dtstart != null ? dtstart : Utc.now(),
      until,
      byweekday,
    };
  }

  /**
   * Returns the last recurrence before the given datetime instance.
   * The inc keyword defines what happens if dt is an occurrence.
   * With inc == True, if dt itself is an occurrence, it will be returned.
   *
   * @return Date or null
   */
  before(dt: DateTime<Utc>, inc = false): DateTime<Utc> {
    // dev(dt.toString(), inc);
    console.log(dt.toString(), "______", inc);
    return Utc.now();
  }

  test() {
    console.log("hello world");
  }
}

export namespace RRule {
  export type Frequency = "daily" | "weekly" | "monthly" | "yearly";

  export enum DaysOfWeek {
    MON = 0,
    TUE = 1,
    WED = 2,
    THU = 3,
    FRI = 4,
    SAT = 5,
    SUN = 6,
  }

  export type Config = Optional<{
    freq: Frequency;
    interval: number;
    dtstart: DateTime<Utc>;
    until: DateTime<Utc>;

    byweekday: DaysOfWeek[];
  }>;

  export type ResolvedConfig = {
    freq: Frequency;
    interval: number;
    dtstart: DateTime<Utc>;
    until: Option<DateTime<Utc>>;
    byweekday: Option<DaysOfWeek[]>;
  };
}

// const blah = {
//   blah: "hi",
// };
