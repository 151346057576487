import { MathUtils } from "../../../lona-js/lona-js/math";
import { Month } from "./month";
import { YearMonthDay } from "../naive-date";
import { DayOfMonth0, DayOfYear0, DaysSinceEpoch, Month0 } from "./units";

export namespace Year {
  export function dseFromYear(year: number): DaysSinceEpoch {
    return (365 * (year - 1970) +
      MathUtils.floorDiv(year - 1969, 4) -
      MathUtils.floorDiv(year - 1901, 100) +
      MathUtils.floorDiv(year - 1601, 400)) as DaysSinceEpoch;
  }

  export function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
}

export class YearInfo {
  readonly year: number;

  readonly isLeapYear: boolean;
  readonly numDays: number;

  readonly firstDay: YearMonthDay;

  constructor(year: number) {
    this.year = year;
    this.isLeapYear = Year.isLeapYear(year);
    this.numDays = this.isLeapYear ? 366 : 365;
    this.firstDay = YearMonthDay.fromYmd1Unchecked(year, 1, 1);
  }

  /**
   * generates (0..<=364/365)
   */
  doy(): Generator<DayOfYear0> {
    return range(this.numDays) as Generator<DayOfYear0>;
  }

  /**
   * generates (31..<=59)
   */
  doyForMonth(month: Month0): Generator<DayOfYear0> {
    const leapYearIdx = this.isLeapYear ? 1 : 0;
    return rangeStart(
      Month.MONTH_START_OF_YEAR[leapYearIdx][month],
      Month.MONTH_START_OF_YEAR[leapYearIdx][month]
    ) as Generator<DayOfYear0>;
  }

  /**
   * generates (0..<=30)
   */
  dom(month: Month0): Generator<DayOfMonth0> {
    const leapYearIdx = this.isLeapYear ? 1 : 0;
    const monthNumDays = Month.DAYS_IN_MONTH[leapYearIdx][month];
    return range(monthNumDays) as Generator<DayOfMonth0>;
  }
}

function* range(end: number): Generator<number> {
  for (let i = 0; i < end; ++i) {
    yield i;
  }
}

function* rangeStart(start: number, end: number): Generator<number> {
  for (let i = start; i < end; ++i) {
    yield i;
  }
}
