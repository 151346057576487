export interface TimezoneTransitions {}

export namespace TimezoneTransitions {
  const defaultCache = new Map();
  let store = async (tzname: string): Promise<TimezoneTransitions[]> => {
    const resp = await fetch(
      `https://static.lona.so/timezones/2023c/1970_2050/${tzname.replaceAll(
        "/",
        "~"
      )}.json`
    );
    const json = await resp.json();
    defaultCache.set(tzname, json);
    return json;
  };

  export function setStore(
    store: (tzname: string) => Promise<TimezoneTransitions[]>
  ) {
    store = store;
  }

  export async function get(tzname: string): Promise<TimezoneTransitions[]> {
    return await store(tzname);
  }
}
